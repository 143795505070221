/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";

/* PACKAGES */
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper/modules";
/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";
/* MATERIAL TAILWIND COMPONENTS */
import {
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Card,
  CardBody,
  Button,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* DATA */
import { stocksList } from "data/investors";

/* UTILS */
import { calculateCompoundInterest } from "utils";

/* ASSETS */
import WarrenBuffetFull from "assets/images/investors/warren-buffet-full.png";

/* ASSETS */
import WarrenBuffet from "assets/images/investors/warren-buffet.png";

/* ASSETS: ETFs */

/* ICONS */
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import { PAYMENT_LINK, PAYMENT_LINK_FREE_TRIAL, SET_FORMAT } from "config";
import CurrencyFormat from "react-currency-format";
import NewCreateAutomationComponent from "components/forms/newCreateAutomation";
import { useSelector } from "react-redux";
import SelectionChart from "components/calculator/selection-chart";
import testEmails from "../../../data/testEmails.json";
import { Link } from "react-router-dom";
import moment from "moment";
import { MONTHLY_PAYMENT_LINK } from "config";

/* INPUT BOX */
const InputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className="w-full block bg-[#F6F6F6] rounded-md px-5 py-2.5 outline-none focus:outline-none text-base placeholder:font-redhat-regular text-black"
    />
  );
};

/* CONTRIBUTING OPTIONS */
const contributingOptions = [
  /* { value: 52, label: "Per week" }, */
  { value: 1, label: "Per Month" },
  /* { value: 1, label: "Per year" }, */
];

/* VALIDATION SCHEMA FOR LOGIN FORM */
const formValidationSchema = yup.object().shape({
  principalAmount: yup.number().required("Principal Amount is required."),
  monthlyContribution: yup
    .number()
    .required("Monthly Contribution is required."),
  interestRate: yup.number().required("Interest Rate is required."),
  timePeriodRange: yup.object().required("Time Period Range is required."),
  timePeriod: yup.number().required("Time Period is required."),
});

const Selection = () => {

  const swiperRefBenefits = useRef(null);
  const swiperRefDrawBacks = useRef(null);
  const [activeSlideBenefits, setActiveSlideBenefits] = useState(0);
  const [activeSlideDrawBacks, setActiveSlideDrawBacks] = useState(0);

  const userId = useSelector((state) => state?.user?.userId);
  const userDetails = useSelector((state) => state?.user?.user);

  /* INITIALIZE STATE VARIABLES */
  const [submitLoading, setSubmitLoading] = useState(false);

  /* GLOBAL STATES */
  const [estimatedInvestment, setEstimatedInvestment] = useState(0);

  const [automationStarted, setAutomationStarted] = useState(false);
  const [pauseAutomation, setPauseAutomation] = useState(false);

  const [activeTab, setActiveTab] = useState("Legendary Investors");
  const [strategySetup, setStrategySetup] = useState(false);

  const [selectedInvestment, setSelectedInvestment] = useState({
    id: 1,
    image: WarrenBuffet,
    name: "Warren Buffet",
    strategyName: "90 / 10 Strategy",
    riskScore: 3.24,
    returnPercentage: 11.77,
    type: "investor",
    underlying: "_BUFFETT",
    about:
      "It has the opportunity to grow quickly, but it can drop just as fast. To balance this risk, bonds that are guaranteed by the U.S. government are added.",
    investorDetails: {
      image: WarrenBuffetFull,
      heading: "Who is Warren Buffet",
      list: [
        "Averaged 20% per year for over 50 years, making him one of the best investors in history",
        "Says the average person should just follow the 90/10 portfolio to reach wealth",
        "Pledged to donate 99% of his wealth",
      ],
      composition: [
        {
          name: "VOO",
          description:
            "Invests in the 500 largest companies in the U.S. This is one of the most popular and best-performing investments in the world.",
          percentage: 90,
        },
        {
          name: "BILS",
          description:
            "Invests in bonds that are guaranteed by the U.S. government. It is extremely safe, so the return is lower.",
          percentage: 10,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who are not afraid of moderate to higher risk.",
        },
        {
          description:
            "Potential for higher returns, because it invests primarily in stocks instead of bonds.",
        },
        {
          description:
            "This strategy uses bonds as a way to balance out some risk.",
        },
        {
          description:
            "Because it invests in the largest 500 companies in the U.S., most money is invested in tech companies like Nvdia, Apple, and Meta.",
        },
      ],
      drawbacks: [
        {
          description:
            "It can sometimes be too volatile for people who do not like risk.",
        },
        {
          description:
            "If the market were to face an extreme decline, it could take longer for this strategy to recover.",
        },
      ],
    },
  });

  /* CHART DATA */
  const [graphData, setGraphData] = useState([]);
  const [withoutInvestment, setWithoutInvestment] = useState([]);
  const [labels, setLabels] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  
  const [startDate, setStartDate] = useState(null);

  const [step, setStep] = useState(1);
  const [benefits, setBenefits] = useState(1);
  const [drawbacks, setDrawbacks] = useState(1);
  const [selectedComposition, setSelectedComposition] = useState({
    name: "VOO",
    description:
      "Invests in the 500 largest companies in the U.S. This is one of the most popular and best-performing investments in the world.",
    percentage: 90,
  });

  const handlePrevSlideBenefits = () => {
    if (swiperRefBenefits.current && activeSlideBenefits > 0) {
      swiperRefBenefits.current.slidePrev();
    }
  };

  const handleNextSlideBenefits = () => {
    if (
      swiperRefBenefits.current &&
      activeSlideBenefits < benefits.length - 1
    ) {
      swiperRefBenefits.current.slideNext();
    }
  };

  const handlePrevSlideDrawBacks = () => {
    if (swiperRefDrawBacks.current && activeSlideDrawBacks > 0) {
      swiperRefDrawBacks.current.slidePrev();
    }
  };

  const handleNextSlideDrawBacks = () => {
    if (
      swiperRefDrawBacks.current &&
      activeSlideDrawBacks < benefits.length - 1
    ) {
      swiperRefDrawBacks.current.slideNext();
    }
  };


  /* HANDLE FORM SUBMIT USING FORMIK */
  const formFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      principalAmount: 20000,
      monthlyContribution: 1500,
      interestRate: 13.24,
      timePeriodRange: { value: 1, label: "Per Month" },
      timePeriod: 10,
    },
    /* VALIDATION SCHEMA  */
    validationSchema: formValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      !initialLoad && setStrategySetup(!strategySetup);
      setInitialLoad(true);
      setSubmitLoading(true);
      const result = calculateCompoundInterest(values);
      setLabels(result?.labels);
      setGraphData(result?.graphData);
      setWithoutInvestment(result?.withoutInvestmentGraph);
      setEstimatedInvestment(result);
      setSubmitLoading(false);
    },
  });

  const getAutomationList = ()=>{
    return true;
  }
  /* COMPOSITION */
  const CompositionInvestor = (props) => {
    return (
      <div className="w-full flex items-center gap-1">
        {props?.details?.map((element, index) => {
          return (
            <div
              key={index}
              className={`h-${
                selectedComposition?.name === element?.name ? "16" : "14"
              } bg-[#008037] bg-opacity-${
                selectedComposition?.name === element?.name
                  ? "80"
                  : index === 0
                  ? "20"
                  : index === 1
                  ? "40"
                  : "60"
              } hover:bg-opacity-30 transition-all duration-300 ease-in-out cursor-pointer ${
                index === 0 && "rounded-l-md"
              } ${
                index === props?.details?.length - 1 && "rounded-r-md"
              } flex items-center justify-center`}
              style={{ width: `${element.percentage}%` }}
              onClick={() => {
                setSelectedComposition(element);
              }}
            >
              <p className="text-md font-redhat-semibold text-black">
                {element?.percentage}%
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  /* PROGRESS BAR */
  const ProgressBar = (props) => {
    return (
      <div className="w-full h-3 rounded-full bg-gradient-to-r from-black to-[#219653] relative flex items-center">
        <div
          style={{ width: `${props.percentage}%` }}
          className="flex items-end"
        >
          <div className="w-2 h-2 bg-white rounded-full ml-auto" />
        </div>
      </div>
    );
  };

  /* INVESTOR BOX */
  const InvestorBox = (props) => {
    return (
      <div
        className={`w-full ${
          selectedInvestment?.id === props?.id
            ? "bg-logoGreen"
            : "bg-white hover:bg-black hover:bg-opacity-5"
        } border border-greyBorder border-opacity-50 shadow-md rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={props.onClick}
      >
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-14 h-14 flex-shrink-0"
            alt={props.name}
          />
          <div className="p-0">
            <h5
              className={`text-md font-redhat-bold ${
                selectedInvestment?.id === props?.id
                  ? "text-white"
                  : "text-black"
              }`}
            >
              {props.name}
            </h5>
            <p
              className={`text-sm font-redhat-semibold ${
                selectedInvestment?.id === props?.id
                  ? "text-white text-opacity-80"
                  : "text-greyText"
              }`}
            >
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* BROKER SELECTION BOX */
  const ETFSelectionBox = (props) => {
    return (
      <div
        className={`${
          selectedInvestment?.id === props?.id
            ? "bg-logoGreen"
            : "bg-white hover:bg-[#F2F2F2]"
        } border border-greyBorder border-opacity-50 shadow-md rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={props.onClick}
      >
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-14 h-14 flex-shrink-0"
            alt={props.strategyName}
          />
          <div className="p-0">
            <h5
              className={`text-md font-redhat-bold ${
                selectedInvestment?.id === props?.id
                  ? "text-white"
                  : "text-black"
              }`}
            >
              {props.name}
            </h5>
            <p
              className={`text-sm font-redhat-semibold ${
                selectedInvestment?.id === props?.id
                  ? "text-white text-opacity-80"
                  : "text-greyText"
              }`}
            >
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* INVESTMENT VALUES */
  const InvestmentValue = (props) => {
    return (
      <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
        <CardBody className="p-5">
          <h5 className="text-xl font-redhat-bold text-black">{props.value}</h5>
          <div className="flex items-center gap-2 mt-1">
            {props.color !== "#FFF" && (
              <div
                className="w-2 h-2 rounded-full flex-shrink-0"
                style={{ backgroundColor: props.color }}
              />
            )}

            <p className="text-sm font-redhat-regular text-black">
              {props.label}
            </p>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT INVESTOR */
  const AboutInvestor = ({ data }) => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={data?.image}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="Jack Bogle"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              {data?.heading}
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              {data?.list?.map((el) => {
                return (
                  <li className="text-base font-redhat-regular text-black">
                    {el}
                  </li>
                );
              })}
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  useEffect(() => {
    if (
      formFormikFunction.values.interestRate !== "" &&
      formFormikFunction.values.monthlyContribution !== "" &&
      formFormikFunction.values.principalAmount !== "" &&
      formFormikFunction.values.timePeriod !== "" &&
      formFormikFunction.values.timePeriodRange !== ""
    ) {
      formFormikFunction.handleSubmit();
    }
  }, [formFormikFunction.values]);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Calculator | ScaleTrade</title>
      </Helmet>

      <div className="container">
        {/* HEADING */}
        <div className="p-0 text-center">
          <h1 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black leading-tight">
            Select your investment
          </h1>
          <p className="mt-1 text-lg font-redhat-regular text-black">
            Automate Legendary Investors and Powerful ETFs from inside your own
            account
          </p>
        </div>

        {/* TABS */}
        <div className="mt-5">
          <Tabs value={activeTab}>
            <TabsHeader
              className="bg-transparent p-0 shadow-none rounded-none gap-2"
              indicatorProps={{
                className:
                  "bg-transparent shadow-none bg-[#F6F6F6] rounded-t-md rounded-b-none",
              }}
            >
              <Tab
                key="Legendary Investors"
                value="Legendary Investors"
                className="text-base font-redhat-semibold text-black bg-white px-8 py-4 whitespace-nowrap shadow-none rounded-t-md rounded-b-none"
                onClick={() => {
                  formFormikFunction.setFieldValue(
                    "interestRate",
                    stocksList?.filter((el) => el?.type === "investor")[0]
                      ?.returnPercentage
                  );
                  setSelectedInvestment(
                    stocksList?.filter((el) => el?.type === "investor")[0]
                  );
                  setSelectedComposition(
                    stocksList?.filter((el) => el?.type === "investor")[0]
                      ?.investorDetails?.composition?.[0]
                  );
                  setBenefits(1);
                  setDrawbacks(1);
                  setActiveTab("Legendary Investors");
                }}
              >
                Legendary Investors
              </Tab>
              <Tab
                key="Powerful ETFs"
                value="Powerful ETFs"
                className="text-base font-redhat-semibold text-black bg-white px-8 py-4 whitespace-nowrap shadow-none rounded-t-md rounded-b-none"
                onClick={() => {
                  formFormikFunction.setFieldValue(
                    "interestRate",
                    stocksList?.filter((el) => el?.type === "etf")[0]
                      ?.returnPercentage
                  );
                  setSelectedInvestment(
                    stocksList?.filter((el) => el?.type === "etf")[0]
                  );
                  setSelectedComposition(
                    stocksList?.filter((el) => el?.type === "etf")[0]
                      ?.investorDetails?.composition?.[0]
                  );
                  setBenefits(1);
                  setDrawbacks(1);
                  setActiveTab("Powerful ETFs");
                }}
              >
                Powerful ETFs
              </Tab>
            </TabsHeader>
            <TabsBody className="p-0">
              {/* LEGENDARY INVESTORS */}
              <TabPanel value="Legendary Investors" className="p-0">
                <div className="bg-[#F6F6F6] p-5 rounded-b-md">
                  <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-3">
                    {stocksList
                      ?.filter((el) => el?.type === "investor")
                      .map((data, index) => (
                        <div className="col-span-1" key={index}>
                          <InvestorBox
                            id={data.id}
                            name={data.name}
                            image={data.image}
                            strategyName={data.strategyName}
                            onClick={() => {
                              formFormikFunction.setFieldValue(
                                "interestRate",
                                data?.returnPercentage
                              );
                              setBenefits(1);
                              setDrawbacks(1);
                              setSelectedComposition(
                                data?.investorDetails?.composition?.[0]
                              );
                              setSelectedInvestment(data);
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </TabPanel>

              {/* POWERFUL ETFs */}
              <TabPanel value="Powerful ETFs" className="p-0">
                <div className="bg-[#F6F6F6] p-5 rounded-b-md">
                  {/* ETFs LIST */}
                  <Swiper
                    navigation
                    modules={[Navigation, EffectFade]}
                    effect="fade"
                    fadeEffect={{ crossFade: false }}
                    slidesPerView={4}
                    spaceBetween={15}
                    className="overflow-visible"
                  >
                    <SwiperSlide>
                      <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                        {stocksList
                          ?.filter((el) => el?.type === "etf")
                          .slice(0, 4)
                          .map((data, index) => (
                            <div className="col-span-1" key={index}>
                              <ETFSelectionBox
                                id={data.id}
                                name={data.name}
                                image={data.image}
                                strategyName={data.strategyName}
                                onClick={() => {
                                  formFormikFunction.setFieldValue(
                                    "interestRate",
                                    data?.returnPercentage
                                  );
                                  setSelectedInvestment(data);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                        {stocksList
                          ?.filter((el) => el?.type === "etf")
                          .slice(4, 8)
                          .map((data, index) => (
                            <div className="col-span-1" key={index}>
                              <ETFSelectionBox
                                id={data.id}
                                name={data.name}
                                image={data.image}
                                strategyName={data.strategyName}
                                onClick={() => {
                                  formFormikFunction.setFieldValue(
                                    "interestRate",
                                    data?.returnPercentage
                                  );
                                  setSelectedInvestment(data);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>

          {/* INPUT DATA AND CHART */}
          <div className="bg-[#F6F6F6] p-5 pt-0 rounded-b-md grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5">
            {/* CALCULATOR DATA */}
            <div className="col-span-1">
              <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                <CardBody className="p-5">
                  <h5 className="text-lg font-redhat-bold text-black">
                    Investment Calculator
                  </h5>
                  <form
                    className="mt-3"
                    onSubmit={formFormikFunction.handleSubmit}
                  >
                    <div className="form-group">
                      <label className="text-base font-redhat-semibold text-black">
                        How much do you have invested now?
                      </label>
                      <div className="mt-2 relative">
                        <CurrencyFormat
                          thousandSeparator={true}
                          value={formFormikFunction.values.principalAmount}
                          onValueChange={(values) => {
                            const { value } = values;
                            formFormikFunction.setFieldValue(
                              "principalAmount",
                              value
                            );
                          }}
                          hintText="10000"
                          className="w-full block bg-[#F6F6F6] rounded-md px-5 py-2.5 outline-none focus:outline-none text-base placeholder:font-redhat-semibold text-black"
                        />
                        {/* <InputBox
                          type="number"
                          name="principalAmount"
                          id="principalAmount"
                          placeholder="10000"
                          value={formFormikFunction.values.principalAmount}
                          onChange={formFormikFunction.handleChange}
                          error={
                            formFormikFunction.errors.principalAmount &&
                            formFormikFunction.touched.principalAmount
                              ? true
                              : false
                          }
                        /> */}

                        <div className="absolute top-3 right-5">
                          <p className="text-base font-redhat-semibold text-greyText">
                            USD
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <label className="text-base font-redhat-semibold text-black">
                        How many years will you invest for?
                      </label>
                      <div className="mt-2 relative">
                        <InputBox
                          type="number"
                          name="timePeriod"
                          id="timePeriod"
                          placeholder="10"
                          value={formFormikFunction.values.timePeriod}
                          onChange={formFormikFunction.handleChange}
                          error={
                            formFormikFunction.errors.timePeriod &&
                            formFormikFunction.touched.timePeriod
                              ? true
                              : false
                          }
                        />

                        <div className="absolute top-3 right-5">
                          <p className="text-base font-redhat-semibold text-greyText">
                            Years
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <label className="text-base font-redhat-semibold text-black">
                        How often will you invest more?
                      </label>
                      <div className="mt-2">
                        <Select
                          options={contributingOptions}
                          classNamePrefix={
                            formFormikFunction.errors.timePeriodRange &&
                            formFormikFunction.touched.timePeriodRange
                              ? "order-error"
                              : "order"
                          }
                          placeholder="Select contribution"
                          className="react-select"
                          value={formFormikFunction.values.timePeriodRange}
                          onChange={(e) => {
                            formFormikFunction.setFieldValue(
                              "timePeriodRange",
                              e
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <label className="text-base font-redhat-semibold text-black">
                        How much will you invest each time?
                      </label>
                      <div className="mt-2 relative">
                        <InputBox
                          type="number"
                          name="monthlyContribution"
                          id="monthlyContribution"
                          placeholder="1500"
                          value={formFormikFunction.values.monthlyContribution}
                          onChange={formFormikFunction.handleChange}
                          error={
                            formFormikFunction.errors.monthlyContribution &&
                            formFormikFunction.touched.monthlyContribution
                              ? true
                              : false
                          }
                        />

                        <div className="absolute top-3 right-5">
                          <p className="text-base font-redhat-semibold text-greyText">
                            USD
                          </p>
                        </div>
                      </div>
                    </div>
                    <Button
                      className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                      type="submit"
                      disabled={submitLoading}
                      onClick={() => {
                        setInitialLoad(false);
                      }}
                      // onClick={() => setStrategySetup(!strategySetup)}
                    >
                      {submitLoading
                        ? "Please wait..."
                        : `✨ Automate ${
                            selectedInvestment?.strategyName
                              ? selectedInvestment?.type === "etf"
                                ? selectedInvestment?.strategyName
                                : selectedInvestment?.name
                              : ""
                          }`}
                      {/* {selectedInvestor} */}
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </div>

            {/* CHART DATA */}
            <div className="col-span-2">
              {/* STATISTICS */}
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                <div className="col-span-1">
                  <InvestmentValue
                    label={"Your invested amount"}
                    value={SET_FORMAT(
                      estimatedInvestment?.totalAmountInvested
                        ? estimatedInvestment?.totalAmountInvested
                        : 0
                    )}
                    color={"#50CFD7"}
                  />
                </div>

                <div className="col-span-1">
                  <InvestmentValue
                    label={"Estimated investment gain"}
                    value={SET_FORMAT(
                      estimatedInvestment?.totalAmount
                        ? estimatedInvestment?.totalAmount -
                            formFormikFunction.values.principalAmount
                        : 0
                    )}
                    color={"#008037"}
                  />
                </div>

                <div className="col-span-1">
                  <InvestmentValue
                    label={"Estimated total value"}
                    value={SET_FORMAT(
                      estimatedInvestment?.totalAmount
                        ? (estimatedInvestment?.totalAmount -
                        formFormikFunction.values.principalAmount) + estimatedInvestment?.totalAmountInvested
                        : 0
                    )}
                    color={"#FFF"}
                  />
                </div>
              </div>

              {/* CHART */}
              <Card className="mt-5 bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                <CardBody className="p-5">
                  {/* CHARTS */}
                  <div className="-ml-5 -mt-3 -mb-3">
                    <SelectionChart
                      values={graphData}
                      labels={labels}
                      withoutInvestment={withoutInvestment}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          {/* INVESTOR BASED DATA */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="bg-[#F6F6F6] p-5 rounded-md">
                  {selectedInvestment && (
                    <AboutInvestor data={selectedInvestment?.investorDetails} />
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <Card className="h-full bg-[#F6F6F6] shadow-none rounded-md">
                  <CardBody className="p-5">
                    <h5 className="text-lg font-redhat-bold text-black">
                      About this strategy
                    </h5>
                    <div className="mt-1">
                      <p className="text-base font-redhat-regular text-black">
                        {selectedInvestment?.about}
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="flex items-center justify-between">
                        <p className="text-base font-redhat-semibold text-black">
                          Estimated Return
                        </p>
                        <p className="text-base font-redhat-semibold text-black">
                          {selectedInvestment?.returnPercentage}% per year (
                          {selectedInvestment?.returnPercentage
                            ? selectedInvestment?.returnPercentage > 10
                              ? "High"
                              : selectedInvestment?.returnPercentage <= 10 &&
                                selectedInvestment?.returnPercentage >= 8
                              ? "Medium"
                              : "Low"
                            : "Low"}
                          )
                        </p>
                      </div>
                      <div className="mt-2">
                        <ProgressBar
                          percentage={
                            selectedInvestment?.returnPercentage
                              ? selectedInvestment?.returnPercentage > 10
                                ? 99
                                : selectedInvestment?.returnPercentage <= 10 &&
                                  selectedInvestment?.returnPercentage >= 8
                                ? 66
                                : 33
                              : 33
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex items-center justify-between">
                        <p className="text-base font-redhat-semibold text-black">
                          Risk Level
                        </p>
                        <p className="text-base font-redhat-semibold text-black">
                          {selectedInvestment?.riskScore} / 5.0 (Medium)
                        </p>
                      </div>
                      <div className="mt-2">
                        <ProgressBar
                          percentage={
                            selectedInvestment?.riskScore
                              ? selectedInvestment?.riskScore > 3.5
                                ? 99
                                : selectedInvestment?.riskScore <= 3.5 &&
                                  selectedInvestment?.riskScore >= 2
                                ? 66
                                : 33
                              : 33
                          }
                        />
                      </div>
                    </div>
                    {selectedInvestment?.investorDetails?.composition ? (
                      <>
                        <div className="mt-3">
                          <p className="text-base font-redhat-semibold text-black">
                            The composition of the investment portfolio
                          </p>
                        </div>
                        <div className="mt-2">
                          {selectedInvestment?.investorDetails?.composition && (
                            <CompositionInvestor
                              details={
                                selectedInvestment?.investorDetails?.composition
                              }
                            />
                          )}
                        </div>
                        <div className="mt-3 bg-white p-3">
                          {selectedComposition && (
                            <div className="flex items-center gap-2">
                              <div className="w-4 h-4 bg-[#008037] rounded" />
                              <p className="text-md font-redhat-semibold text-black">
                                {selectedComposition?.percentage}% is invested
                                into {selectedComposition?.name}
                              </p>
                            </div>
                          )}
                          <p className="text-sm font-redhat-semibold text-black mt-2">
                            {selectedComposition?.description}
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="mt-5">
                        <p className="text-base font-redhat-semibold text-black">
                          Top holdings
                        </p>
                        <div className="mt-3 bg-white rounded-md p-3">
                          {selectedInvestment?.investorDetails?.holdings?.map(
                            (element, index) => {
                              return element?.link === false ? (
                                <div
                                  key={index}
                                  className={`flex items-center justify-between ${
                                    index !== 0 && "mt-2"
                                  }`}
                                >
                                  <p className="text-base font-redhat-semibold text-black">
                                    {element?.name}
                                  </p>
                                  <p className="text-base font-redhat-semibold text-black">
                                    {element?.percentage}%
                                  </p>
                                </div>
                              ) : (
                                <Link to={element?.redirectLink}>
                                  <Button className="mt-3 w-full text-center py-2 shadow-none hover:shadow-none bg-white hover:bg-logoGreen hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out text-logoGreen normal-case font-redhat-semibold text-base">
                                    See the full list
                                  </Button>
                                </Link>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
              <div className="col-span-1">
                <Card className="h-full shadow-none bg-[#F6F6F6] rounded-lg">
                  <CardBody className="p-5">
                    <h5 className="text-lg font-redhat-bold text-black">
                      Benefits
                    </h5>
                    <div className="mt-3">
                      <Card className="bg-white w-full rounded-md shadow-none">
                      <CardBody className="p-5">
                            <Swiper
                              modules={[Navigation, EffectFade]}
                              navigation={{
                                nextEl: ".benefit-next",
                                prevEl: ".benefit-prev",
                              }}
                              effect="fade"
                              fadeEffect={{ crossFade: true }}
                              slidesPerView={1}
                              onSwiper={(swiper) => {
                                swiperRefBenefits.current = swiper;
                              }}
                              onSlideChange={(swiper) => {
                                setActiveSlideBenefits(swiper.activeIndex);
                              }}
                            >
                              {selectedInvestment?.investorDetails?.benefits?.map(
                                (element, index) => {
                                  return (
                                    <SwiperSlide key={index}>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        {element?.description}
                                      </p>
                                    </SwiperSlide>
                                  );
                                }
                              )}
                            </Swiper>
                            <div className="mt-7 flex items-center justify-between">
                              <Button
                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-prev"
                                onClick={handlePrevSlideBenefits}
                              >
                                <FaChevronLeft className="w-4 h-4 text-black" />
                              </Button>
                              <div className="flex items-center gap-2">
                                {selectedInvestment?.investorDetails?.benefits?.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          index === activeSlideBenefits
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                    );
                                  }
                                )}
                              </div>
                              <Button
                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-next"
                                onClick={handleNextSlideBenefits}
                              >
                                <FaChevronRight className="w-4 h-4 text-black" />
                              </Button>
                            </div>
                          </CardBody>
                      </Card>
                    </div>
                    {console.log("benefits", benefits)}
                    {console.log("drawbacks", drawbacks)}
                    <div className="mt-5">
                      <h5 className="text-lg font-redhat-bold text-black">
                        Drawbacks
                      </h5>
                      <div className="mt-3">
                        <Card className="bg-white w-full rounded-md shadow-none">
                        <CardBody className="p-5">
                              <Swiper
                                modules={[Navigation, EffectFade]}
                                navigation={{
                                  nextEl: ".drawback-next",
                                  prevEl: ".drawback-prev",
                                }}
                                initialSlide={drawbacks - 1}
                                slidesPerView={1}
                                effect="fade"
                                fadeEffect={{ crossFade: true }}
                                onSwiper={(swiper) => {
                                  swiperRefDrawBacks.current = swiper;
                                }}
                                onSlideChange={(swiper) => {
                                  setActiveSlideDrawBacks(swiper.activeIndex);
                                }}
                              >
                                {selectedInvestment?.investorDetails?.drawbacks?.map(
                                  (element, index) => {
                                    return (
                                      <SwiperSlide key={index}>
                                        <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                          {element?.description}
                                        </p>
                                      </SwiperSlide>
                                    );
                                  }
                                )}
                              </Swiper>

                              <div className="mt-7 flex items-center justify-between">
                                <Button
                                  className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out drawback-prev"
                                  onClick={handlePrevSlideDrawBacks}
                                >
                                  <FaChevronLeft className="w-4 h-4 text-black" />
                                </Button>
                                <div className="flex items-center gap-2">
                                  {selectedInvestment?.investorDetails?.drawbacks?.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={`w-2.5 h-2.5 rounded-full ${
                                            index === activeSlideDrawBacks
                                              ? "bg-[#008037]"
                                              : "bg-[#DDD]"
                                          }`}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                                <Button
                                  className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out drawback-next"
                                  onClick={handleNextSlideDrawBacks}
                                >
                                  <FaChevronRight className="w-4 h-4 text-black" />
                                </Button>
                              </div>
                            </CardBody>
                        </Card>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* STRATEGY SETUP MODAL */}
      <Dialog
        open={strategySetup}
        handler={() => setStrategySetup(!strategySetup)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          {step === 1 && (
            <NewCreateAutomationComponent
              setAutomationDialog={setStrategySetup}
              strategySetup={strategySetup}
              setStep={setStep}
              setStartDate={setStartDate}
              calculatorDetails={formFormikFunction?.values}
              totalAmountInvested={estimatedInvestment?.totalAmountInvested}
              selectedInvestment={selectedInvestment}
              getAutomationList={getAutomationList}
            />
          )}

{step === 2 &&
            (userDetails?.isSubscribed ? (
              <div className="p-0">
                <h5 className="text-lg font-roboto-bold text-black text-center">
                  {userDetails?.isSubscribed
                    ? "Your new Automation is STARTED!"
                    : "Your Automation is Ready!"}
                </h5>
                <div className="mt-3">
                  <img
                    src={require("../../../assets/images/icons/automation-success.png")}
                    className="w-4/5 mx-auto h-auto"
                    alt="Your automation is ready"
                  />
                </div>
                <div className="mt-5">
                  <h5 className="text-md font-roboto-semibold text-black text-center">
                    {userDetails?.isSubscribed
                      ? `Your investment will be made on ${moment(
                          startDate
                        ).format("MM/DD/YYYY")}`
                      : "Start your ScaleTrade plan to begin using your new automation"}
                    .{" "}
                  </h5>
                  {userDetails?.isSubscribed && (
                    <h5 className="text-md font-roboto-semibold text-black text-center">
                      Keep your automations running to stay on track with your
                      investment plan.
                    </h5>
                  )}
                </div>
                <div className="flex items-center justify-center">
                  <Button
                    className="mt-5 w-fit mx-auto px-10 shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-roboto-bold transition-all duration-300 ease-in-out"
                    onClick={() => {
                      setStep(1);
                      !userDetails?.isSubscribed
                        ? testEmails?.find((el) => el === userDetails?.email)
                          ? window.open(
                              `${PAYMENT_LINK_FREE_TRIAL}?client_reference_id=${userId}`,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          : window.open(
                              `${PAYMENT_LINK}?client_reference_id=${userId}`,
                              "_blank",
                              "noopener,noreferrer"
                            )
                        : setStrategySetup(!strategySetup);
                    }}
                  >
                    {!userDetails?.isSubscribed
                      ? "Start ScaleTrade Plan"
                      : "Great! Let's go"}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="p-0">
                <h5 className="w-4/5 mx-auto text-xl font-redhat-bold text-black leading-tight text-center">
                  Select a ScaleTrade plan to start your new automation...
                </h5>
                <div className="w-fit mx-auto mt-8 relative">
                  <div className="flex items-center justify-center gap-3">
                    <img
                      src={require("../../../assets/images/icons/new-grad-icon-01.png")}
                      className="w-14 h-14 object-cover"
                      alt="Investors"
                    />
                    <img
                      src={require("../../../assets/images/icons/new-grad-icon-04.png")}
                      className="w-14 h-14 object-cover"
                      alt="Investors"
                    />
                    <img
                      src={require("../../../assets/images/icons/new-grad-icon-03.png")}
                      className="w-14 h-14 object-cover"
                      alt="Investors"
                    />
                    <img
                      src={require("../../../assets/images/icons/new-grad-icon-02.png")}
                      className="w-14 h-14 object-cover"
                      alt="Investors"
                    />
                  </div>
                </div>
                <div className="mt-8">
                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <div className="bg-[#ECECEC] p-3 rounded-md">
                        <h5 className="text-3xl font-redhat-bold text-black">
                          $6.99 <span className="text-base">/ month</span>
                        </h5>
                        <Button
                          onClick={() => {
                            !userDetails?.isSubscribed
                              ? testEmails?.find(
                                  (el) => el === userDetails?.email
                                )
                                ? window.open(
                                    `${PAYMENT_LINK_FREE_TRIAL}?client_reference_id=${userId}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                                : window.open(
                                    `${MONTHLY_PAYMENT_LINK}?client_reference_id=${userId}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                              : setStrategySetup(!strategySetup);
                          }}
                          className="mt-1 px-5 py-2 w-full text-sm border border-black shadow-none hover:shadow-none normal-case  bg-logoGreen rounded-md"
                        >
                          Select plan
                        </Button>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="bg-[#D8FFE9] p-3 rounded-md border border-[#E3E3E3]">
                        <h5 className="text-3xl font-redhat-bold text-black">
                          $39 <span className="text-base">/ year</span>
                        </h5>
                        <Button
                          onClick={() => {
                            !userDetails?.isSubscribed
                              ? testEmails?.find(
                                  (el) => el === userDetails?.email
                                )
                                ? window.open(
                                    `${PAYMENT_LINK_FREE_TRIAL}?client_reference_id=${userId}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                                : window.open(
                                    `${PAYMENT_LINK}?client_reference_id=${userId}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                              : setStrategySetup(!strategySetup);
                          }}
                          className="mt-1 px-5 py-2 w-full text-sm shadow-none border border-black hover:shadow-none normal-case  bg-logoGreen rounded-md"
                        >
                          Select plan
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </DialogBody>
      </Dialog>

      {/* AUTOMATION STARTED MODAL */}
      <Dialog
        open={automationStarted}
        handler={() => setAutomationStarted(!automationStarted)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          <div className="p-0">
            <h5 className="text-lg font-redhat-bold text-black text-center">
              Your New Automation is{" "}
              <span className="italic font-redhat-bold">STARTED</span>!
            </h5>
            <div className="mt-3">
              <img
                src={require("../../../assets/images/icons/automation-success.png")}
                className="w-4/5 mx-auto h-auto"
                alt="Your automation is ready"
              />
            </div>
            <div className="mt-5">
              <h5 className="text-md font-redhat-bold text-black text-center">
                Your first investment will be made on 5/24/24
              </h5>
              <h5 className="mt-1 text-md font-redhat-regular text-black text-center">
                Keep your automations running to stay on track with your
                investment plan.
              </h5>
            </div>
            <div className="flex items-center justify-center">
              <Button
                className="mt-5 w-fit mx-auto px-10 shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                onClick={() => {
                  setAutomationStarted(!automationStarted);
                }}
              >
                Great! Let's go
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      {/* PAUSE MODAL */}
      <Dialog
        open={pauseAutomation}
        handler={() => setPauseAutomation(!pauseAutomation)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          <div className="p-0">
            <h5 className="text-lg font-redhat-bold text-black text-center">
              Are you sure?
            </h5>
            <div className="mt-3">
              <img
                src={require("../../../assets/images/icons/automation-pause.png")}
                className="w-4/5 mx-auto h-auto"
                alt="Pause automation"
              />
            </div>
            <div className="mt-5">
              <h5 className="text-md font-redhat-regular text-black text-center">
                By pausing your automation, you’re interrupting the powerful
                compounding process.
              </h5>
            </div>
            <div className="flex items-center gap-0">
              <Button
                className="mt-5 w-fit mx-auto whitespace-nowrap shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                onClick={() => {
                  setPauseAutomation(!pauseAutomation);
                }}
              >
                Actually, don't pause
              </Button>
              <Button className="mt-5 w-fit whitespace-nowrap mx-auto shadow-none hover:shadow-none bg-[#494949] hover:bg-opacity-80 rounded-md normal-case text-base text-white font-redhat-bold transition-all duration-300 ease-in-out">
                Confirm pause
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Selection;
