/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REACT  */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import countryList from "react-select-country-list";

/* IMPORT ROUTER DOM FOR LINK */
import { Link, useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Checkbox, Radio, Tooltip } from "@material-tailwind/react";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/actions/user.action";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import TDAmeritrade from "assets/images/logos/td-ameritrade.png";
import Charles from "assets/images/logos/charles-schwab.jpeg";
import Robinhood from "assets/images/logos/robinhood.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Tradier from "assets/images/logos/tradier.png";
import Fidelity from "assets/images/logos/fidelity.jpeg";
/* ICONS */
import { FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineExclamationCircle } from "react-icons/ai";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import {
  getAuthorizationLinkApi,
  addUserAccountApi,
  profileUpdate,
} from "../../service/api";
import brokers from "data/brokers";
import CustomTooltip from "components/customToolTip";

/* VALIDATION SCHEMA FOR LOGIN FORM */
const stepOneOnBoardingFormValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  mobile: yup.string().required("Phone Number is required."),
  country: yup.object().required("Country is required."),
  currentlyEmployedStatus: yup.string(),
  hearAboutUs: yup
    .object()
    .required("Please select platform you know about ScaleTrade."),
});

/* VALIDATION SCHEMA FOR VERIFY OTP FORM */
const onBoardingFormValidationSchema = yup.object().shape({
  investingExp: yup.string().required("Trading Experience is required."),
  mainGoalWithScaleTrade: yup.object().required("Select Options in  Goal."),
  bigMarketDrops: yup.string().required("Big Market Drops is required."),
  numberOfAccounts: yup.string().required("numberOfAccounts is required."),
  brokerageUsed: yup.array().required("Is required."),
});

const goalOptions = [
  {
    value: "Tracking and Analyzing My Investments",
    label: "Tracking and Analyzing My Investments",
  },
  {
    value: "Automating My Investment Strategy",
    label: "Automating My Investment Strategy",
  },
  {
    value: "Managing Multiple Brokerage Accounts Efficiently",
    label: "Managing Multiple Brokerage Accounts Efficiently",
  },
  {
    value: "Learning About Investing",
    label: "Learning About Investing",
  },
  {
    value: "Other",
    label: "Other",
  },
];

/* DIALOG INPUT */
const DialogInputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

const sourceOptions = [
  { value: "Google", label: "Google" },
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter", label: "Twitter" },
  { value: "Instagram", label: "Instagram" },
  { value: "YouTube", label: "YouTube" },
  { value: "Friends/Family", label: "Friends/Family" },
  { value: "Other", label: "Other" },
];

const ageGroups = [
  { value: "Under 18", label: "Under 18" },
  { value: "18-24", label: "18-24" },
  { value: "25-30", label: "25-30" },
  { value: "31-36", label: "31-36" },
  { value: "37-45", label: "37-45" },
  { value: "46+", label: "46+" },
];

const investmentGoals = [
  { value: "Capital Preservation", label: "Capital Preservation" },
  { value: "Wealth Accumulation", label: "Wealth Accumulation" },
  { value: "Retirement Planning", label: "Retirement Planning" },
  { value: "Education Funding", label: "Education Funding" },
  { value: "Tax Optimization", label: "Tax Optimization" },
];

const OnBoardingForm = () => {
  /* INITIALIZE FUNCTIONS */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user);

  const countryOptions = useMemo(() => countryList().getData(), []);

  /* INITIALIZE STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [employmentStatus, setEmploymentStatus] = useState("No");
  const [onboardingNextStep, setOnboardingNextStep] = useState("stepOne");
  const [investingExp, setInvestingExp] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [riskFactor, setRiskFactor] = useState("");

  /* HANDLE LOGIN FORM USING FORMIK */
  const StepOneOnBoardingFormFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      country: "",
      currentlyEmployedStatus: "",
      hearAboutUs: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: stepOneOnBoardingFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      // setLoading(true);
      /* CALL LOGIN API */
      if (validPhoneNumber) {
        setOnboardingNextStep("stepTwo");
      } else {
        toast.error("Please enter Valid Phone Number");
      }
    },
  });

  const OnBoardingFormFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      investingExp: "",
      investmentGoals: "",
      numberOfAccounts: "",
      brokerageUsed: "",
      twoFactorAuthentication: false,
    },
    /* VALIDATION SCHEMA  */
    validationSchema: onBoardingFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setLoading(true);

      /* CALL LOGIN API */
      const obj = {};
      obj["firstName"] = StepOneOnBoardingFormFormikFunction?.values?.firstName;
      obj["lastName"] = StepOneOnBoardingFormFormikFunction?.values?.lastName;

      obj["country"] =
        StepOneOnBoardingFormFormikFunction?.values?.country?.label;

      obj["mobile"] = StepOneOnBoardingFormFormikFunction?.values?.mobile;

      obj["hearAboutUs"] =
        StepOneOnBoardingFormFormikFunction?.values?.hearAboutUs?.value;

      obj["investingExp"] = OnBoardingFormFormikFunction?.values?.investingExp;

      obj["investmentGoals"] =
        OnBoardingFormFormikFunction?.values?.investmentGoals?.value;

      obj["numberOfAccounts"] =
        OnBoardingFormFormikFunction?.values?.numberOfAccounts;

      let tempArray = [];

      if (OnBoardingFormFormikFunction?.values?.brokerageUsed?.length > 0) {
        OnBoardingFormFormikFunction?.values?.brokerageUsed?.forEach(
          (element) => {
            tempArray.push(element?.value);
          }
        );
      }

      obj["brokerageUsed"] = tempArray;
      obj["twoFactorAuthentication"] = values?.twoFactorAuthentication;

      profileUpdate(obj)
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status) {
            /* SHOW NOTIFICATION */
            toast.success(res?.message);

            /* SET USER LOGGED IN STATUS TRUE */
            dispatch(userAction.setUser(res?.data));
            // welcome();
            setOnboardingNextStep("success");
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  const handleValidatePhoneNumber = (value) => {
    if (value) {
      const isValid = isValidPhoneNumber(value);
      setValidPhoneNumber(isValid);
      if (isValid) {
        StepOneOnBoardingFormFormikFunction.setFieldValue("mobile", value);
      } else {
        StepOneOnBoardingFormFormikFunction.setFieldError(
          "mobile",
          "Please enter Valid Phone Number"
        );
      }
      return isValid;
    } else {
      StepOneOnBoardingFormFormikFunction.setFieldValue("mobile", "");
    }
  };

  const AccountAccordion = (props) => {
    return (
      <div
        className="p-3 bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg mt-2 cursor-pointer"
        onClick={() => handleGetAuthLink(props?.slug)}
      >
        <div className="flex items-center gap-3">
          <img
            src={props?.brokerImg}
            className="w-10 h-10 object-contain rounded"
            alt={props?.brokerName}
          />
          <div className="p-0">
            <h5 className="text-base font-redhat-semibold text-black">
              {props?.brokerName}
            </h5>
            {/* <p className="text-sm font-redhat-regular text-greyText">
                        ${props.balance}
                    </p> */}
          </div>
        </div>
      </div>
    );
  };

  const handleGetAuthLink = (slug) => {
    addUserAccountApi(userDetails?.userId)
      .then((res) => {
        // if (res?.data?.url) {
        //   window.location.href = res?.data?.url
        // }
      })
      .catch((err) => {
        console.error("handleGetAuthLink", err);
      });

    getAuthorizationLinkApi(userDetails?.userId, slug)
      .then((res) => {
        if (res?.data?.url) {
          window.open(res?.data?.url, "_blank", "noopener,noreferrer");
        }
      })
      .catch((err) => {
        console.error("handleGetAuthLink", err);
      });
  };

  return onboardingNextStep === "success" ? (
    <div className="py-5 px-8">
      <h3 className="text-xl font-redhat-extrabold text-black">
        Success! Now link your broker
      </h3>
      <p className="mt-1 text-base font-redhat-regular text-[#494949]">
        Let's get you on your way to stress-free investing.
      </p>
      <div className="mt-3">
        {brokers.map((data) => (
          <React.Fragment key={data.id}>
            <AccountAccordion
              brokerName={data.brokerName}
              brokerImg={data.brokerImg}
              slug={data.slug}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="mt-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          <span className="underline cursor-pointer">
            I don't see my brokerage here
          </span>
        </p>
        <p className="text-sm font-redhat-semibold text-gray-500 text-center mt-5">
          By linking your brokerage and starting automations, you agree to the
          terms of our Disclosure Agreement
        </p>
      </div>
    </div>
  ) : (
    <div className="py-5 px-8">
      <h5 className="text-2xl font-redhat-extrabold text-black">Welcome!</h5>
      <p className="mt-2 text-base font-redhat-regular text-[#494949]">
        Similar to when you open a new brokerage account, we just have a few
        questions for you to answer before you get started.
      </p>

      {onboardingNextStep === "stepTwo" && (
        <form
          className="mt-5"
          onSubmit={OnBoardingFormFormikFunction.handleSubmit}
        >
          <div className="grid grid-cols-2 gap-3">
            {/* INVESTING EXP. */}
            <div className="form-group col-span-2">
              <label className="text-base font-redhat-semibold text-greyText">
                Investment Experience{" "}
              </label>
              <div className="mt-1 hidden">
                <Select
                  options={[
                    {
                      value: "Beginner",
                      label:
                        "Beginner (Less than 1 year of Investing Experience)",
                    },
                    {
                      value: "Intermediate",
                      label:
                        "Intermediate (1 to 5 years of Investing Experience)",
                    },
                    {
                      value: "Advanced",
                      label:
                        "Advanced (More than 5 years of Investing Experience)",
                    },
                  ]}
                  className={`react-select ${
                    OnBoardingFormFormikFunction.errors.investingExp &&
                    OnBoardingFormFormikFunction.touched.investingExp &&
                    "is-invalid"
                  }`}
                  classNamePrefix="order"
                  placeholder="Select..."
                  value={OnBoardingFormFormikFunction?.values?.investingExp}
                  onChange={(e) => {
                    OnBoardingFormFormikFunction.setFieldValue(
                      "investingExp",
                      e
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-3 mt-2">
                {[
                  {
                    value: "Beginner",
                    label:
                      "Beginner (Less than 1 year of Investing Experience)",
                  },
                  {
                    value: "Intermediate",
                    label:
                      "Intermediate (1 to 5 years of Investing Experience)",
                  },
                  {
                    value: "Advanced",
                    label:
                      "Advanced (More than 5 years of Investing Experience)",
                  },
                ].map((data, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      OnBoardingFormFormikFunction.setFieldValue(
                        "investingExp",
                        data?.value
                      );
                      setInvestingExp(data?.value);
                    }}
                    className={`col-span-1 cursor-pointer w-full font-roboto-regular font-normal shadow-none hover:shadow-none ${
                      investingExp === data?.value
                        ? "bg-logoGreen border border-logoGreen text-white"
                        : "bg-white border border-[#E5E5E5] text-black"
                    } rounded-md px-5 py-2 normal-case hover:bg-logoGreen hover:bg-opacity-10 hover:text-black transition-all duration-300 ease-in-out text-base`}
                  >
                    <CustomTooltip text={data?.label}>
                      {data?.value}
                    </CustomTooltip>
                  </div>
                ))}
              </div>
            </div>

            {/* PRIMARY INVESTMENT GOALS */}
            <div className="form-group col-span-2 mt-2 hidden">
              <label className="text-base font-redhat-semibold text-greyText">
                What are your primary investment goals?
              </label>
              <div className="mt-1">
                <Select
                  options={investmentGoals}
                  className="react-select"
                  classNamePrefix="order"
                  placeholder="Select.."
                  value={OnBoardingFormFormikFunction?.values?.investmentGoals}
                  onChange={(e) => {
                    OnBoardingFormFormikFunction.setFieldValue(
                      "investmentGoals",
                      e
                    );
                  }}
                />
              </div>
            </div>

            {/* BROKERAGES USED */}
            <div className="form-group col-span-2 mt-2">
              <label className="text-base font-redhat-semibold text-greyText">
                Which brokerage(s) do you use?
              </label>
              <div className="mt-1">
                <Select
                  options={[
                    { value: "Alpaca", label: "Alpaca" },
                    { value: "Alpaca Paper", label: "Alpaca Paper" },
                    { value: "Robinhood", label: "Robinhood" },
                    { value: "Tradestation", label: "Tradestation" },
                    {
                      value: "Tradestation Simulated",
                      label: "Tradestation Simulated",
                    },
                    { value: "Tradier", label: "Tradier" },
                    { value: "Webull", label: "Webull" },
                    { value: "Fidelity", label: "Fidelity" },

                    { value: "Fidelity", label: "Fidelity" },
                    { value: "Schwab", label: "Schwab" },
                    { value: "Meryril Lynch", label: "Meryril Lynch" },
                    { value: "Etrade", label: "Etrade" },
                  ]}
                  className={`react-select ${
                    OnBoardingFormFormikFunction.errors.brokerageUsed &&
                    OnBoardingFormFormikFunction.touched.brokerageUsed &&
                    "is-invalid"
                  }`}
                  isMulti={true}
                  classNamePrefix="order"
                  placeholder="Select all that apply"
                  value={OnBoardingFormFormikFunction?.values?.brokerageUsed}
                  onChange={(e) => {
                    OnBoardingFormFormikFunction.setFieldValue(
                      "brokerageUsed",
                      e
                    );
                  }}
                />
              </div>
            </div>

            <div className="form-group col-span-2 mt-2">
              <label className="text-base font-redhat-semibold text-greyText">
                How many different investment accounts do you have?
              </label>
              <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 gap-3 mt-2">
                {["1", "2", "3", "4", "5+"].map((data, index) => (
                  <div className="col-span-1" key={index}>
                    <Button
                      onClick={() => {
                        OnBoardingFormFormikFunction.setFieldValue(
                          "numberOfAccounts",
                          data
                        );
                        setAccountNo(data);
                      }}
                      className={`w-full font-roboto-regular font-normal shadow-none hover:shadow-none ${
                        accountNo === data
                          ? "bg-logoGreen border border-logoGreen text-white"
                          : "bg-white border border-[#E5E5E5] text-black"
                      } rounded-md px-5 py-2 normal-case hover:bg-logoGreen hover:bg-opacity-10 hover:text-black transition-all duration-300 ease-in-out text-base`}
                    >
                      {data}
                    </Button>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group col-span-2 mt-2">
              <label className="text-base font-redhat-semibold text-greyText">
                How much risk do you prefer when investing?
              </label>

              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-3 mt-2">
                {["Minimal risk", "Some risk", "More risk"].map(
                  (data, index) => (
                    <div className="col-span-1" key={index}>
                      <Button
                        onClick={() => {
                          OnBoardingFormFormikFunction.setFieldValue(
                            "bigMarketDrops",
                            data
                          );
                          setRiskFactor(data);
                        }}
                        className={`w-full font-roboto-regular font-normal shadow-none hover:shadow-none ${
                          riskFactor === data
                            ? "bg-logoGreen border border-logoGreen text-white"
                            : "bg-white border border-[#E5E5E5] text-black"
                        } rounded-md px-5 py-2 normal-case hover:bg-logoGreen hover:bg-opacity-10 hover:text-black transition-all duration-300 ease-in-out text-base`}
                      >
                        {data}
                      </Button>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* BROKERAGES ACCOUNT COUNT */}
            <div className="form-group col-span-2 mt-2 hidden">
              <label className="text-base font-redhat-semibold text-greyText">
                How many brokerage accounts do you have?
              </label>
              <div className="mt-1">
                <Select
                  options={[
                    { value: "0", label: "0" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "more", label: "More" },
                  ]}
                  className={`react-select ${
                    OnBoardingFormFormikFunction.errors.numberOfAccounts &&
                    OnBoardingFormFormikFunction.touched.numberOfAccounts &&
                    "is-invalid"
                  }`}
                  classNamePrefix="order"
                  placeholder="Select.."
                  value={OnBoardingFormFormikFunction?.values?.numberOfAccounts}
                  onChange={(e) => {
                    OnBoardingFormFormikFunction.setFieldValue(
                      "numberOfAccounts",
                      e
                    );
                  }}
                />
              </div>
            </div>

            {/* MAIN GOALS WITH SCALETRADE */}
            <div className="form-group col-span-2 mt-2">
              <label className="text-base font-redhat-semibold text-greyText">
                What is your goal with ScaleTrade?
              </label>
              <div className="mt-1">
                <Select
                  options={goalOptions}
                  className={`react-select ${
                    OnBoardingFormFormikFunction.errors
                      .mainGoalWithScaleTrade &&
                    OnBoardingFormFormikFunction.touched
                      .mainGoalWithScaleTrade &&
                    "is-invalid"
                  }`}
                  classNamePrefix="order"
                  placeholder="Select.."
                  value={
                    OnBoardingFormFormikFunction?.values?.mainGoalWithScaleTrade
                  }
                  onChange={(e) => {
                    OnBoardingFormFormikFunction.setFieldValue(
                      "mainGoalWithScaleTrade",
                      e
                    );
                  }}
                />
                {console.log("Values", OnBoardingFormFormikFunction?.values)}
                {console.log("ERrro", OnBoardingFormFormikFunction?.errors)}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Button
              type="submit"
              className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
            >
              Submit
            </Button>
          </div>
        </form>
      )}

      {onboardingNextStep === "stepOne" && (
        <form
          className="mt-5"
          onSubmit={StepOneOnBoardingFormFormikFunction.handleSubmit}
        >
          <div className="grid grid-cols-2 gap-3">
            {/* FIRSTNAME */}
            <div className="form-group col-span-1">
              <label className="text-base font-redhat-semibold text-greyText">
                First Name
              </label>
              <DialogInputBox
                type="text"
                name="firstName"
                id="firstName"
                placeholder=""
                value={StepOneOnBoardingFormFormikFunction.values.firstName}
                onChange={StepOneOnBoardingFormFormikFunction.handleChange}
                error={
                  StepOneOnBoardingFormFormikFunction.errors.firstName &&
                  StepOneOnBoardingFormFormikFunction.touched.firstName
                    ? true
                    : false
                }
              />
            </div>

            {/* LASTNAME */}
            <div className="form-group col-span-1">
              <label className="text-base font-redhat-semibold text-greyText">
                Last Name
              </label>
              <DialogInputBox
                type="text"
                name="lastName"
                id="lastName"
                placeholder=""
                value={StepOneOnBoardingFormFormikFunction.values.lastName}
                onChange={StepOneOnBoardingFormFormikFunction.handleChange}
                error={
                  StepOneOnBoardingFormFormikFunction.errors.lastName &&
                  StepOneOnBoardingFormFormikFunction.touched.lastName
                    ? true
                    : false
                }
              />
            </div>

            {/* PHONENUMBER */}
            <div className="form-group col-span-2 mt-2">
              <label className="text-base font-redhat-semibold text-greyText">
                Phone Number
              </label>
              <div className="relative">
                <PhoneInput
                  placeholder="Phone number"
                  defaultCountry="US"
                  className={`phone-input  ${
                    StepOneOnBoardingFormFormikFunction.errors.lastName &&
                    StepOneOnBoardingFormFormikFunction.touched.lastName &&
                    "error"
                  }`}
                  id="phone"
                  name="phone"
                  value={StepOneOnBoardingFormFormikFunction?.values?.mobile}
                  onChange={(e) => {
                    handleValidatePhoneNumber(e);
                  }}
                />

                {StepOneOnBoardingFormFormikFunction.errors.lastName &&
                  StepOneOnBoardingFormFormikFunction.touched.lastName && (
                    <div className="absolute top-3 right-3">
                      <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                    </div>
                  )}

                {StepOneOnBoardingFormFormikFunction?.values?.mobile?.length ===
                  10 && (
                  <div className="absolute top-3 right-3">
                    <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                  </div>
                )}
              </div>
            </div>

            {/* COUNTRY */}
            <div className="col-span-2">
              <div className="form-group">
                <label className="text-base font-redhat-semibold text-greyText">
                  Country
                </label>
                <Select
                  options={[
                    { value: "US", label: "United States" },
                    ...countryOptions,
                  ]}
                  className={`react-select ${
                    StepOneOnBoardingFormFormikFunction.errors.country &&
                    StepOneOnBoardingFormFormikFunction.touched.country &&
                    "is-invalid"
                  }`}
                  classNamePrefix="order"
                  placeholder="Select country"
                  value={StepOneOnBoardingFormFormikFunction?.values?.country}
                  onChange={(e) => {
                    StepOneOnBoardingFormFormikFunction.setFieldValue(
                      "country",
                      e
                    );
                  }}
                />
              </div>
            </div>

            {/* AGE */}
            <div className="col-span-1 hidden">
              <div className="form-group">
                <label className="text-base font-redhat-semibold text-greyText">
                  Age
                </label>
                <Select
                  options={ageGroups}
                  className={`react-select ${
                    StepOneOnBoardingFormFormikFunction.errors.ageGroup &&
                    StepOneOnBoardingFormFormikFunction.touched.ageGroup &&
                    "is-invalid"
                  }`}
                  classNamePrefix="order"
                  placeholder="Select age"
                  value={StepOneOnBoardingFormFormikFunction?.values?.ageGroup}
                  onChange={(e) => {
                    StepOneOnBoardingFormFormikFunction.setFieldValue(
                      "ageGroup",
                      e
                    );
                  }}
                />
              </div>
            </div>

            <div className="form-group col-span-2 mt-2">
              <label className="text-base font-redhat-semibold text-greyText">
                Are you currently employed?
              </label>
              <div className="flex items-center gap-2">
                <div className="-mt-1">
                  <Radio
                    name="employed"
                    ripple={false}
                    labelProps={{ className: "font-redhat-semibold" }}
                    label="Yes"
                    onChange={() => {
                      setEmploymentStatus("Yes");
                      StepOneOnBoardingFormFormikFunction.setFieldValue(
                        "currentlyEmployedStatus",
                        "Yes"
                      );
                    }}
                    defaultChecked={
                      StepOneOnBoardingFormFormikFunction?.values
                        ?.currentlyEmployedStatus === "Yes"
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="-mt-1">
                  <Radio
                    name="employed"
                    ripple={false}
                    label="No"
                    labelProps={{ className: "font-redhat-semibold" }}
                    defaultChecked={
                      StepOneOnBoardingFormFormikFunction?.values
                        ?.currentlyEmployedStatus === "No"
                        ? true
                        : false
                    }
                    onChange={() => {
                      setEmploymentStatus("No");
                      StepOneOnBoardingFormFormikFunction.setFieldValue(
                        "currentlyEmployedStatus",
                        "No"
                      );
                    }}
                  />
                </div>
                <div className="-mt-1">
                  <Radio
                    name="employed"
                    ripple={false}
                    label="Prefer not to answer"
                    labelProps={{ className: "font-redhat-semibold" }}
                    defaultChecked={
                      StepOneOnBoardingFormFormikFunction?.values
                        ?.currentlyEmployedStatus === "PreferNotToAnswer"
                        ? true
                        : false
                    }
                    onChange={() => {
                      setEmploymentStatus("PreferNotToAnswer");
                      StepOneOnBoardingFormFormikFunction.setFieldValue(
                        "currentlyEmployedStatus",
                        "PreferNotToAnswer"
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            {/* {employmentStatus === "Yes" && (
              <div className="col-span-2">
                <div className="grid grid-cols-2 gap-3">
                  <div className="form-group col-span-1">
                    <label className="text-sm font-redhat-semibold text-greyText">
                      Employer
                    </label>
                    <DialogInputBox
                      type="text"
                      name="employer"
                      id="employer"
                      placeholder=""
                      value={
                        StepOneOnBoardingFormFormikFunction.values.employer
                      }
                      onChange={
                        StepOneOnBoardingFormFormikFunction.handleChange
                      }
                      error={
                        StepOneOnBoardingFormFormikFunction.errors.employer &&
                        StepOneOnBoardingFormFormikFunction.touched.employer
                          ? true
                          : false
                      }
                    />
                  </div>

                  <div className="form-group col-span-1">
                    <label className="text-sm font-redhat-semibold text-greyText">
                      Job Title
                    </label>
                    <DialogInputBox
                      type="text"
                      name="jobTitle"
                      id="jobTitle"
                      placeholder=""
                      value={
                        StepOneOnBoardingFormFormikFunction.values.jobTitle
                      }
                      onChange={
                        StepOneOnBoardingFormFormikFunction.handleChange
                      }
                      error={
                        StepOneOnBoardingFormFormikFunction.errors.jobTitle &&
                        StepOneOnBoardingFormFormikFunction.touched.jobTitle
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
                <div className="grid-cols-2 lg:grid-cols-1"></div>
              </div>
            )} */}

            <div className="form-group col-span-2">
              <label className="text-base font-redhat-semibold text-greyText">
                How did you hear about ScaleTrade?
              </label>
              <Select
                options={sourceOptions}
                className="react-select"
                classNamePrefix="order"
                placeholder="Select"
                onChange={(e) => {
                  StepOneOnBoardingFormFormikFunction.setFieldValue(
                    "hearAboutUs",
                    e
                  );
                }}
                value={StepOneOnBoardingFormFormikFunction?.values?.hearAboutUs}
              />
              {StepOneOnBoardingFormFormikFunction.errors.hearAboutUs &&
                StepOneOnBoardingFormFormikFunction.touched.hearAboutUs && (
                  <small
                    style={{
                      color: "red",
                    }}
                  >
                    {StepOneOnBoardingFormFormikFunction.errors.hearAboutUs}
                  </small>
                )}
            </div>
          </div>
          <div className="mt-5">
            <Button
              type="submit"
              className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
            >
              Next
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default OnBoardingForm;
