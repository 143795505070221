/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";

/* PACKAGES */
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import Select from "react-select";
import moment from "moment";

/* IMPORT CONFIG */
import { SET_FORMAT } from "config";

/* DATA */
import brokers from "data/brokers";

/* CUSTOM COMPONENTS */
import BrokerTile from "components/calculator/broker-tile";

/* CHARTS */
import DonutChart from "components/dashboard/donut";
import ColumnChart from "components/dashboard/column";
import AreaChart from "components/dashboard/area";

/* HELPERS */
import HelperGetData from "components/others/helpers";
import useDividendHelper from "components/others/dividendCalculation";

/* API IMPORT */
import {
  getBenchmarkInformationAPI,
  getDividendInformationApi,
  temporaryWebhook,
} from "service/api";

/* ACTIONS */
import userAction from "../../redux/actions/user.action";

/* TOAST NOTIFICATION */
import { toast } from "react-toastify";

/* ICONS */
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdArrowDropdown,
  IoMdArrowDropup,
} from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { IoClose, IoCloseCircleOutline } from "react-icons/io5";
import {
  MdOutlinePlayCircle,
  MdOutlinePauseCircleOutline,
} from "react-icons/md";

/* IMAGES IMPORT */
// ICONS
import ConnectIcon from "assets/images/icons/connect.png";

// ASSETS
import TDAmeritrade from "assets/images/logos/td-ameritrade.png";
import Charles from "assets/images/logos/charles-schwab.jpeg";
import Robinhood from "assets/images/logos/robinhood.png";
import Alpaca from "assets/images/logos/alpaca.png";
import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";
import CharlesSchwab from "assets/images/logos/charles-schwab.jpeg";
import ETrade from "assets/images/logos/e-trade.webp";
import { automationHelper } from "components/others/automationStart";

const Dashboard = () => {
  /* GLOBAL USER STATE */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const message = query.get("message");

  const userId = useSelector((state) => state?.user?.userId);
  const accountId = useSelector((state) => state?.user?.accountId);
  const userDetails = useSelector((state) => state?.user?.user);
  const loggedInStatus = useSelector((state) => state?.user?.loggedIn);
  const accountsList = useSelector((state) => state?.user?.allAccounts);
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );

  /* STATE VARIABLES */
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedAccountId, setSelectedAccountId] = useState("all");

  const [accountOptions, setAccountOptions] = useState([]);

  const [cumulativePortfolioValue, setCumulativePortfolioValue] = useState(0.0);

  const [todayChange, setTodayChange] = useState({});
  const [percentageChange, setPercentageChange] = useState(0);
  const [ratioValue, setRatioValue] = useState();

  const [areaGraphData, setAreaGraphData] = useState(null);

  const [accountAccordionState, setAccountAccordionState] = useState(null);

  /* CONSTANTS */
  const today = new Date();
  const hrs = today.getHours();

  /* CALLBACKS */

  const {
    estimatedDividend,
    barGraphData,
    detailsLoading,
    getDividendCallback,
  } = useDividendHelper();

  /* COLUMNS */
  const columns = [
    {
      name: "Investment",
      grow: 3,
      selector: (row, index) => (
        <p className="text-base font-redhat-bold text-black">
          {row?.order?.company} ({row?.order?.underlying})
        </p>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => (
        <p className="text-base font-redhat-semibold text-black">
          {row?.order?.units?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "Price",
      selector: (row) => (
        <p className="text-base font-redhat-semibold text-black">
          {SET_FORMAT(row?.order?.information?.price)}
        </p>
      ),
    },
    {
      name: "Total Value",
      selector: (row) => (
        <p className="text-base font-redhat-semibold text-black">
          {SET_FORMAT(row?.order?.information?.price * row?.order?.units)}
        </p>
      ),
    },
    {
      name: "Total Change",
      selector: (row) => (
        <p
          className={`text-base font-redhat-semibold ${
            parseFloat(row?.order?.information?.open_pnl) >= 0
              ? "text-[#219653]"
              : "text-blueText"
          }`}
        >
          {SET_FORMAT(
            parseFloat(row?.order?.information?.price) *
              parseFloat(row?.order?.units) -
              parseFloat(row?.order?.information?.avg_purchase_price) *
                parseFloat(row?.order?.units)
          )}{" "}
          (
          {parseFloat(
            ((row?.order?.information?.price -
              row?.order?.information?.avg_purchase_price) /
              row?.order?.information?.avg_purchase_price) *
              100
          ).toFixed(2)}
          %)
        </p>
      ),
    },
    {
      name: "Average Cost",
      selector: (row) => (
        <p className="text-base font-redhat-semibold text-black">
          {SET_FORMAT(row?.order?.information?.avg_purchase_price)}
        </p>
      ),
    },
    {
      name: "Asset",
      selector: (row) => (
        <p className="text-base font-redhat-semibold text-black">
          {row?.order?.assetSubset}
        </p>
      ),
    },
  ];

  /* FUNCTIONS */
  const callWebhookFunction = () => {
    temporaryWebhook(userId)
      .then((res) => {
        console.log("response", res);
      })
      .catch((e) => {
        console.log("Error:", e);
      });
  };
  const handleAreaGraphData = () => {
    let tempArrayValues = [];
    let tempArrayLabels = [];

    let tempArray = [];

    if (selectedAccountId === "all") {
      allConnectedAccountsList?.totals?.balances
        ?.slice(
          selectedPeriod === "7days"
            ? -8
            : selectedPeriod === "30days"
            ? -30
            : selectedPeriod === "165days"
            ? -165
            : selectedPeriod === "1Year"
            ? -365
            : selectedPeriod === "5Year"
            ? -1825
            : -12
        )
        ?.forEach((element) => {
          tempArrayValues.push(
            parseFloat(element?.balance ? element?.balance : 0)
          );
          tempArrayLabels.push(moment(element?.date).format("YYYY-MM-DD"));

          tempArray.push({
            balance: parseFloat(element?.balance ? element?.balance : 0),
            date: moment(element?.date).format("YYYY-MM-DD"),
          });
        });

      let ratio = {};
      ratio.cs = allConnectedAccountsList?.totals?.ratios?.find(
        (el) => el?.code === "cs"
      )?.ratio;
      ratio.cash = allConnectedAccountsList?.totals?.ratios?.find(
        (el) => el?.code === "cash"
      )?.ratio;
      ratio.et = allConnectedAccountsList?.totals?.ratios?.find(
        (el) => el?.code === "et"
      )?.ratio;

      setRatioValue(ratio);
      setCumulativePortfolioValue(allConnectedAccountsList?.totals?.totalValue);
      tempArrayValues[tempArrayValues.length - 1] =
        allConnectedAccountsList?.totals?.totalValue;
    } else {
      allConnectedAccountsList?.accounts
        ?.find((el) => el?.accountId === selectedAccountId)
        ?.balances?.slice(
          selectedPeriod === "7days"
            ? -8
            : selectedPeriod === "30days"
            ? -30
            : selectedPeriod === "165days"
            ? -165
            : selectedPeriod === "1Year"
            ? -365
            : selectedPeriod === "5Year"
            ? -1825
            : -12
        )
        ?.forEach((element) => {
          tempArrayValues.push(parseFloat(element?.balance));
          tempArrayLabels.push(moment(element?.date).format("YYYY-MM-DD"));

          tempArray.push({
            balance: parseFloat(element?.balance ? element?.balance : 0),
            date: moment(element?.date).format("YYYY-MM-DD"),
          });
        });
      let ratio = {};
      setRatioValue(ratio);
      ratio.et = allConnectedAccountsList?.accounts
        ?.find((el) => el?.accountId === selectedAccountId)
        ?.ratios?.find((el) => el?.code === "et")?.ratio;
      ratio.cash = allConnectedAccountsList?.accounts
        ?.find((el) => el?.accountId === selectedAccountId)
        ?.ratios?.find((el) => el?.code === "cash")?.ratio;
      ratio.cs = allConnectedAccountsList?.accounts
        ?.find((el) => el?.accountId === selectedAccountId)
        ?.ratios?.find((el) => el?.code === "cs")?.ratio;

      setRatioValue(ratio);

      setCumulativePortfolioValue(
        allConnectedAccountsList?.accounts?.find(
          (el) => el?.accountId === selectedAccountId
        )?.totalValue
      );
    }

    if (
      allConnectedAccountsList?.accounts?.find(
        (el) => el?.accountId === selectedAccountId
      )
    ) {
      tempArrayValues[tempArrayValues.length - 1] =
        allConnectedAccountsList?.accounts?.find(
          (el) => el?.accountId === selectedAccountId
        )?.totalValue;
    }

    const tempSPY = [];
    tempArrayValues?.forEach((el) => {
      tempSPY.push();
    });

    let percentageChange =
      ((tempArrayValues?.[tempArrayValues?.length - 1] -
        tempArrayValues?.[tempArrayValues?.length - 2]) /
        tempArrayValues[tempArrayValues?.length - 2]) *
      100;

    setTodayChange({
      percentage: percentageChange ? percentageChange : 0,
      amount:
        tempArrayValues?.[tempArrayValues?.length - 1] -
        tempArrayValues?.[tempArrayValues?.length - 2],
    });

    if (tempArrayLabels?.length === 1) {
      if (
        allConnectedAccountsList?.accounts?.find(
          (el) => el?.accountId === selectedAccountId
        )
      ) {
        tempArrayValues.push(
          allConnectedAccountsList?.accounts?.find(
            (el) => el?.accountId === selectedAccountId
          )?.totalValue
        );
      } else {
        tempArrayValues.push(allConnectedAccountsList?.totals?.totalValue);
      }
    }

    if (tempArrayValues?.length > 0) {
      let length = tempArrayValues?.length - 1;

      let percentageChange =
        ((tempArrayValues[length] - tempArrayValues[0]) /
          tempArrayValues[length]) *
        100;
      setPercentageChange({
        amount: tempArrayValues[length] - tempArrayValues[0],
        percentage: percentageChange,
      });
    }

    setAreaGraphData({
      labels: tempArrayLabels,
      portFolioData: tempArrayValues,
      spyData: null,
    });
  };

  /* USE-EFFECTS */
  useEffect(() => {
    if (loggedInStatus) {
      dispatch(userAction.setApiStatus(true));
    } else {
      toast.info("Please sign in to ScaleTrade Account");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (message) {
      callWebhookFunction();
      dispatch(userAction.setApiStatus(true));
    }
  }, [message]);

  useEffect(() => {
    if (loggedInStatus) {
      if (allConnectedAccountsList) {
        let tempArray = [{ value: "all", label: "All portfolios" }];
        if (accountsList?.accounts?.length > 0) {
          accountsList?.accounts?.forEach((ele) => {
            tempArray.push({
              label: ele?.brokerage + " # ---" + ele?.accountNumber?.slice(-4),
              value: ele?.accountId,
            });
          });
        }
        setAccountOptions(tempArray);
        setSelectedAccountId("all");
        handleAreaGraphData();
      }
    }
  }, [allConnectedAccountsList]);

  useEffect(() => {
    if (selectedAccountId && allConnectedAccountsList) {
      handleAreaGraphData();
      getDividendCallback(allConnectedAccountsList, selectedAccountId);
      // handleGetDividedValueFunction();
    }
  }, [allConnectedAccountsList, selectedAccountId, selectedPeriod]);

  useEffect(() => {
    if (userDetails?.isSubscribed === true) {
      automationHelper(userDetails?._id)
    }
  }, [userDetails?.isSubscribed]);
  console.log("ENV File", process.env);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7 ">
      <HelperGetData />

      <Helmet>
        <title>Dashboard | ScaleTrade</title>
      </Helmet>

      <div className="container mx-auto">
        {/* HEADING */}
        <h1 className="text-3xl font-redhat-extrabold text-black">
          {hrs < 12
            ? "Good Morning"
            : hrs >= 12 && hrs <= 17
            ? "Good Afternoon"
            : hrs >= 17 && hrs <= 24
            ? "Good Evening"
            : ""}
          , {userDetails?.firstName}
        </h1>

        {/* CHARTS AND PORTFOLIO DETAILS */}
        <div className="w-full">
          {/* CHARTS AND OTHER DETAILS */}
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5 mt-5">
            {accountsList === undefined ||
            accountsList?.accounts?.length === 0 ||
            accountsList?.length === 0 ? (
              <div className="col-span-2">
                <div className="w-full bg-white border border-inputBox rounded-lg">
                  <div className="relative w-full h-full">
                    {/* TIME RANGE */}
                    <div className="absolute top-24 left-0 w-full">
                      <div className="flex items-center justify-center gap-1 flex-wrap">
                        <div className="block items-center justify-center">
                          <p className="text-2xl font-redhat-regular ">
                            Link your account below to see your dashboard
                            insights
                          </p>
                          <img
                            src={ConnectIcon}
                            className="mt-3 mx-auto"
                            alt="Connect"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="p-0">
                      <AreaChart myPortfolio={areaGraphData} />
                    </div>

                    {/* TIME RANGE */}
                    <div className="absolute bottom-14 left-0 w-full">
                      <div className="flex items-center justify-center gap-1 flex-wrap">
                        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-semibold text-black px-5 py-2 transition-all duration-300 ease-in-out">
                          Last 7 days
                        </Button>

                        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-semibold text-black px-5 py-2 transition-all duration-300 ease-in-out">
                          Benchmark
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-span-2">
                <div className="w-full bg-white border border-inputBox rounded-lg">
                  <div className="relative w-full h-full">
                    {/* DETAILS */}
                    <div className="p-5 pb-0">
                      <div className="block xl:flex lg:flex items-center">
                        <div className="pr-5 border-r-0 xl:border-r lg:border-r border-greyBorder">
                          <div className="flex items-center">
                            <div className="min-w-[10vh] w-full">
                              <Select
                                options={accountOptions}
                                classNamePrefix={
                                  selectedPeriod === "SPY" ? "main_spy" : "main"
                                }
                                className="react-select"
                                value={accountOptions?.find(
                                  (el) => el?.value === selectedAccountId
                                )}
                                onChange={(e) => {
                                  setSelectedAccountId(e?.value);
                                }}
                              />
                            </div>
                          </div>
                          <h5 className="-mt-1 text-xl items-center font-redhat-extrabold text-black">
                            {SET_FORMAT(
                              cumulativePortfolioValue
                                ? cumulativePortfolioValue
                                : 0
                            )}
                          </h5>
                        </div>
                        {/* TODAY'S CHANGE */}
                        <div className="flex">
                          <div className="px-0 xl:px-5 lg:px-5 pt-2">
                            <p className="text-base font-redhat-regular text-black">
                              Change today
                            </p>
                            <h5
                              className={`${
                                todayChange?.amount >= 0
                                  ? "text-green-500"
                                  : "text-red-500"
                              } mt-1 text-xl font-redhat-extrabold`}
                            >
                              {todayChange?.amount > 0 && "+"}{" "}
                              {SET_FORMAT(
                                todayChange?.amount ? todayChange?.amount : 0
                              )}{" "}
                              ({todayChange?.amount > 0 && "+"}
                              {console.log(todayChange?.amount)}
                              {todayChange?.percentage
                                ? todayChange?.percentage === Infinity
                                  ? parseFloat(100).toFixed(2)
                                  : todayChange?.percentage?.toFixed(2)
                                : 0.0}
                              %)
                            </h5>
                          </div>
                        </div>

                        {/* PERIOD CHANGE */}
                        <div
                          className={
                            selectedPeriod === "7days" ||
                            selectedPeriod === "30days" ||
                            selectedPeriod === "165days" ||
                            selectedPeriod === "1Year" ||
                            selectedPeriod === "5Year"
                              ? "flex pr-5 border-l-0 xl:border-l lg:border-l border-greyBorder"
                              : "hidden"
                          }
                        >
                          <div className="px-0 xl:px-5 lg:px-5 pt-2">
                            <p className="text-base font-redhat-regular text-black">
                              Period change
                            </p>
                            <h5
                              className={`${
                                percentageChange?.amount >= 0
                                  ? "text-green-500"
                                  : "text-red-500"
                              } mt-1 text-xl font-redhat-extrabold`}
                            >
                              {percentageChange?.amount > 0 && "+"}{" "}
                              {SET_FORMAT(
                                percentageChange?.amount
                                  ? percentageChange?.amount
                                  : 0
                              )}{" "}
                              ({percentageChange?.percentage > 0 && "+"}
                              {percentageChange?.percentage
                                ? percentageChange?.percentage?.toFixed(2)
                                : 0.0}
                              {"%  "})
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* GRAPH */}
                    <div className="p-0">
                      <AreaChart myPortfolio={areaGraphData} />
                    </div>

                    {/* TIME RANGE */}
                    <div
                      className={`absolute  left-0 w-full ${
                        selectedPeriod === "7days" ? "bottom-20" : "bottom-20"
                      }`}
                    >
                      <div className="flex items-center justify-center gap-1 flex-wrap">
                        <Button
                          onClick={() => {
                            setSelectedPeriod(
                              selectedPeriod === "7days" ? false : "7days"
                            );
                          }}
                          className={`shadow-none hover:shadow-none ${
                            selectedPeriod === "7days"
                              ? "bg-[#d6d6d6]"
                              : "bg-body"
                          }  hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-base text-black px-5 py-2 transition-all duration-300 ease-in-out`}
                        >
                          Last 7 days
                        </Button>

                        <Button
                          onClick={() => {
                            setSelectedPeriod(
                              selectedPeriod === "30days" ? false : "30days"
                            );
                          }}
                          className={`shadow-none hover:shadow-none ${
                            selectedPeriod === "30days"
                              ? "bg-[#d6d6d6]"
                              : "bg-body"
                          }  hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-base text-black px-5 py-2 transition-all duration-300 ease-in-out`}
                        >
                          1 Month
                        </Button>

                        <Button
                          onClick={() => {
                            setSelectedPeriod(
                              selectedPeriod === "165days" ? false : "165days"
                            );
                          }}
                          className={`shadow-none hover:shadow-none ${
                            selectedPeriod === "165days"
                              ? "bg-[#d6d6d6]"
                              : "bg-body"
                          }  hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-base text-black px-5 py-2 transition-all duration-300 ease-in-out`}
                        >
                          6 Months
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedPeriod(
                              selectedPeriod === "1Year" ? false : "1Year"
                            );
                          }}
                          className={`shadow-none hover:shadow-none ${
                            selectedPeriod === "1Year"
                              ? "bg-[#d6d6d6]"
                              : "bg-body"
                          }  hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-base text-black px-5 py-2 transition-all duration-300 ease-in-out`}
                        >
                          1 Year
                        </Button>

                        <Button
                          onClick={() => {
                            setSelectedPeriod(
                              selectedPeriod === "5Year" ? false : "5Year"
                            );
                          }}
                          className={`shadow-none hover:shadow-none ${
                            selectedPeriod === "5Year"
                              ? "bg-[#d6d6d6]"
                              : "bg-body"
                          }  hover:bg-opacity-90 border border-inputBox rounded-md normal-case text-base font-redhat-base text-black px-5 py-2 transition-all duration-300 ease-in-out`}
                        >
                          5 Years
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* PORTFOLIO DETAILS */}
            {accountsList === undefined ||
            accountsList?.accounts?.length === 0 ||
            accountsList?.length === 0 ? (
              <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
                <div className="w-full bg-white border border-inputBox rounded-lg p-5 pb-0">
                  {/* ALLOCATION */}
                  <div className="p-0">
                    <h5 className="text-lg font-redhat-bold text-black">
                      Your portfolio allocation
                    </h5>
                    <div className="flex items-center gap-3">
                      <div className="w-3/5">
                        <div className="py-2 ">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <div className="w-2 h-2 rounded-full bg-greyBorder flex-shrink-0" />
                              <p className="text-base font-redhat-semibold text-black">
                                No data to display
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-2/5">
                        <DonutChart />
                      </div>
                    </div>
                  </div>

                  {/* DIVIDEND INCOME */}
                  <div className="mt-5">
                    <h5 className="text-lg font-redhat-bold text-black">
                      Dividend Income
                    </h5>
                    <p className="text-sm font-redhat-regular text-greyText">
                      Connect your account to see this info
                    </p>
                    <div className="mt-1">
                      <ColumnChart />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
                <div className="w-full bg-white border border-inputBox rounded-lg p-5 pb-0">
                  {/* ALLOCATION */}
                  <div className="p-0">
                    <h5 className="text-lg font-redhat-bold text-black">
                      Your portfolio allocation
                    </h5>
                    <div className="flex items-center gap-5">
                      <div className="w-3/5">
                        <div className="py-2 border-b border-greyBorder">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <div className="w-2 h-2 rounded-full bg-[#05D15A] flex-shrink-0" />
                              <p className="text-base font-redhat-semibold text-black">
                                ETFs
                              </p>
                            </div>
                            <p className="text-base font-redhat-bold text-black">
                              {(
                                (ratioValue?.et ? ratioValue?.et : 0) * 100
                              ).toFixed(2)}
                              %
                            </p>
                          </div>
                        </div>
                        <div className="py-2 border-b border-greyBorder">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <div className="w-2 h-2 rounded-full bg-[#52B6FF] flex-shrink-0" />
                              <p className="text-base font-redhat-semibold text-black">
                                Cash
                              </p>
                            </div>
                            <p className="text-base font-redhat-bold text-black">
                              {(
                                (ratioValue?.cash ? ratioValue?.cash : 0) * 100
                              ).toFixed(2)}
                              %
                            </p>
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <div className="w-2 h-2 rounded-full bg-[#e15f30] flex-shrink-0" />
                              <p className="text-base font-redhat-semibold text-black">
                                Stocks
                              </p>
                            </div>
                            <p className="text-base font-redhat-bold text-black">
                              {(
                                (ratioValue?.cs ? ratioValue?.cs : 0) * 100
                              ).toFixed(2)}
                              %
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-2/5">
                        <DonutChart
                          graphData={[
                            parseFloat(
                              (
                                (ratioValue?.et ? ratioValue?.et : 0) * 100
                              ).toFixed(2)
                            ),
                            parseFloat(
                              (
                                (ratioValue?.cash ? ratioValue?.cash : 0) * 100
                              ).toFixed(2)
                            ),
                            parseFloat(
                              (
                                (ratioValue?.cs ? ratioValue?.cs : 0) * 100
                              ).toFixed(2)
                            ),
                          ]}
                        />
                      </div>
                    </div>
                  </div>

                  {/* DIVIDEND INCOME */}
                  <div className="mt-5">
                    <h5 className="text-lg font-redhat-bold text-black">
                      Dividend Income
                    </h5>
                    <p className="text-sm font-redhat-regular text-greyText">
                      Estimated Next 12 months:{" "}
                      {SET_FORMAT(estimatedDividend ? estimatedDividend : 0)}
                    </p>
                    <div className="mt-1">
                      <ColumnChart barGraphData={barGraphData} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* YOUR CONNECTED ACCOUNTS */}

        {accountsList === undefined ||
        accountsList?.accounts?.length === 0 ||
        accountsList?.length === 0 ? (
          <div className="mt-5 ">
            <h5 className="text-lg font-redhat-bold text-black">
              Connect your brokerage account to see your dashboard information
            </h5>
            <div className="mt-3">
              <div className="grid grid-cols-2 xl:grid-cols-7 lg:grid-cols-1 gap-5">
                {brokers.map((data) => (
                  <div className="col-span-1" key={data.id}>
                    <BrokerTile
                      brokerName={data.brokerName}
                      brokerImg={data.brokerImg}
                      slug={data.slug}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <h2 className="text-xl font-redhat-bold text-black">
              Your connected accounts
            </h2>

            <div className="mt-0">
              {accountsList?.accounts?.map((data, index) => {
                return (
                  <div key={index}>
                    <Accordion
                      open={accountAccordionState === index}
                      icon={
                        accountAccordionState === index ? (
                          <IoIosArrowUp className="w-5 h-5 text-greyText" />
                        ) : (
                          <IoIosArrowDown className="w-5 h-5 text-greyText" />
                        )
                      }
                      onClick={() =>
                        setAccountAccordionState(
                          accountAccordionState === index ? null : index
                        )
                      }
                      className="mt-3 border border-greyBorder rounded-lg"
                    >
                      <AccordionHeader
                        className="p-3 bg-greyBg bg-opacity-50 rounded-none border-b-0"
                        onClick={() => setAccountAccordionState(index)}
                      >
                        <div className="flex items-center gap-3">
                          <img
                            src={
                              data.brokerage === "ALPACA"
                                ? Alpaca
                                : data.brokerage === "ALPACA_PAPER"
                                ? Alpaca
                                : data.brokerage === "TRADIER"
                                ? Tradier
                                : data.brokerage === "ROBINHOOD"
                                ? Robinhood
                                : data.brokerage === "TRADESTATION"
                                ? TradeStation
                                : data.brokerage === "TRADESTATION_SIM"
                                ? TradeStation
                                : data.brokerage === "WEBULL"
                                ? Webull
                                : data.brokerage === "SCHWAB"
                                ? CharlesSchwab
                                : data.brokerage === "ETRADE"
                                ? ETrade
                                : null
                            }
                            className="w-10 h-10 object-contain rounded"
                            alt={data?.brokerage}
                          />
                          <div className="p-0">
                            <h5 className="text-base font-redhat-base text-black">
                              {"Brokerage # --" +
                                data?.accountNumber?.slice(-4)}
                            </h5>
                            <p className="text-sm font-redhat-regular text-greyText">
                              {allConnectedAccountsList?.accounts?.find(
                                (el) => el?.accountId === data?.accountId
                              )?.cash
                                ? SET_FORMAT(
                                    allConnectedAccountsList?.accounts?.find(
                                      (el) => el?.accountId === data?.accountId
                                    )?.totalValue
                                  )
                                : SET_FORMAT(0)}
                            </p>
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody className="p-0 bg-transparent rounded-b-lg border-t border-greyBorder">
                        <div className="p-0">
                          <DataTable
                            className="trade-table overflow-x-auto rounded-b-lg"
                            data={
                              allConnectedAccountsList?.accounts?.find(
                                (el) => el?.accountId === data?.accountId
                              )?.active
                            }
                            columns={columns}
                            responsive
                            noDataComponent={
                              <p className="text-black font-redhat-bold text-base px-5 py-5">
                                No investments found for this account.{" "}
                                <span
                                  onClick={() => navigate("/automate")}
                                  className="text-logoGreen font-redhat-bold underline cursor-pointer"
                                >
                                  Create an automation to start investing.
                                </span>
                              </p>
                            }
                          />
                        </div>
                      </AccordionBody>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
export default Dashboard;
