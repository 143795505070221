/* eslint-disable no-redeclare */

const { toast } = require("react-toastify");
const { getUserAutomation, userStrategySetApi, editUserAutomation } = require("service/api");

/* eslint-disable no-unused-vars */
module.exports.automationHelper = async (userId) => {
  console.log("userId", userId);

  const automationList = await getUserAutomation(userId);

  automationList?.data?.docs?.forEach(async (element) => {
    console.log("element", element);
    await userStrategySetApi(userId, element?.automationDetails).then(async (res) => {
        toast.success(res?.message);
        await editUserAutomation(element?._id)
      })
      .catch((er) => {
        console.log("Error: ", er);
        toast.error(er?.response?.data?.message);
      })
      .finally(() => {

      });
  });

  return {
    userId,
  };
};
